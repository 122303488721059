<template>
  <div class="box-body">
    <table-items :columns="columns"
                 :data_search="data_search"
                 :relations="relations"
                 component="log-item"
                 :api_resource="'/employee/' + employeeId + '/logs'">
    </table-items>
  </div>
</template>

<script>
import Vue from "vue";
import TableItems from "@/components/pagination/table-items.vue";

Vue.component("log-item", (resolve) => {
  return require(["./../../logs/item"], resolve);
});

export default {
  name: "employee-logs",

  components: {
    TableItems,
  },

  props: {
    employeeId: {
      type: [String, Number],
      required: true,
    },
    data_search: {
      type: String,
      required: false,
    },
  },

  data: () => ({
    employee: null,

    columns: [
      {
        title: "ID",
        class: null,
        style: null,
      },
      {
        title: "Имя",
        class: null,
        style: null,
      },
      {
        title: "URL",
        class: null,
        style: null,
      },
      {
        title: "IP",
        class: null,
        style: null,
      },
      {
        title: "Дата",
        class: null,
        style: null,
      },
    ],
    relations: [
      "employee",
    ],
  }),

  async created() {
    try {

    } catch (error) {
      errorHandler(error);
    }
  },
};
</script>

<style scoped>

</style>
